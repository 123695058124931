exports.components = {
  "component---src-pages-3-minuten-js": () => import("./../../../src/pages/3minuten.js" /* webpackChunkName: "component---src-pages-3-minuten-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuel-js": () => import("./../../../src/pages/aktuel.js" /* webpackChunkName: "component---src-pages-aktuel-js" */),
  "component---src-pages-analog-js": () => import("./../../../src/pages/analog.js" /* webpackChunkName: "component---src-pages-analog-js" */),
  "component---src-pages-danube-js": () => import("./../../../src/pages/danube.js" /* webpackChunkName: "component---src-pages-danube-js" */),
  "component---src-pages-dialog-js": () => import("./../../../src/pages/dialog.js" /* webpackChunkName: "component---src-pages-dialog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konflikt-js": () => import("./../../../src/pages/konflikt.js" /* webpackChunkName: "component---src-pages-konflikt-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-moleculus-js": () => import("./../../../src/pages/moleculus.js" /* webpackChunkName: "component---src-pages-moleculus-js" */),
  "component---src-pages-neuen-js": () => import("./../../../src/pages/neuen.js" /* webpackChunkName: "component---src-pages-neuen-js" */),
  "component---src-pages-objects-js": () => import("./../../../src/pages/objects.js" /* webpackChunkName: "component---src-pages-objects-js" */),
  "component---src-pages-schulen-js": () => import("./../../../src/pages/schulen.js" /* webpackChunkName: "component---src-pages-schulen-js" */),
  "component---src-pages-spaces-js": () => import("./../../../src/pages/spaces.js" /* webpackChunkName: "component---src-pages-spaces-js" */),
  "component---src-pages-trotz-js": () => import("./../../../src/pages/trotz.js" /* webpackChunkName: "component---src-pages-trotz-js" */)
}

